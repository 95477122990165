import React, { useState } from 'react';
import "../CSS/career.css"
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
import Footer1 from "../../Footer1/Footer1"
import Footerlast from '../../Footerlast/Footerlast';
import cv from "../../Images/cv.jpeg"
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Backend_url from '../../../Important/BackEnd';
import Token from '../../../Important/TOKEN';

const JobApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    qualification: '',
    designation: '',
    experienceYears: '',
    experienceMonths: '',
    expectedSalary: '',
    address: '',
    resume: null
  });

  const [fileError, setFileError] = useState('');
  const [isFileValid, setIsFileValid] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      const file = files[0];
      const allowedExtensions = ['pdf', 'doc', 'docx'];

      // Get file extension
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFormData({ ...formData, resume: file });
        setFileError(''); // No error
        setIsFileValid(true); // Enable submit button
      } else {
        setFileError('Please upload a valid document (PDF, DOC, DOCX).');
        setIsFileValid(false); // Disable submit button
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirm = window.confirm(`Are you sure you want to submit with current fill data?`)
    if (!confirm) {
      return
    }

    debugger
    // https://tc2.yes-bm.com/api/career/create
    const data = {
      "Name": formData.name,
      "MobileNo": formData.mobile,
      "Email": formData.email,
      "Qulification": formData.qualification,
      "Designation": formData.designation,
      "Experience": formData.experienceYears,
      "Address": formData.address,
      "ExpectedSalary": formData.expectedSalary
    }

    try {
      await axios.post(`${Backend_url}/career/create`, data, {
        headers: {
          'Authorization': `Bearer ` + Token
        }
      }).then((res) => {
        console.log(res)
        alert(`Successfully `)
      })

      console.log(formData);
    } catch (error) {
      console.log(error);
    }
  };


  // ----------------handlecaptchachange-------------------------------
  const hanclecaptchachange = () => {
    try {

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Footer />
      <Navbar />
      <div className='career-footer sm:!flex-row sm:!p-0'>
        <div className='career1'>
          <h1>CAREER</h1>
        </div>
        <div className='career2 '>
          <ul>
            <li>Home</li>
            <li>/</li>
            <li>career</li>
          </ul>
        </div>
      </div>
      <div className='main-form'>
        <div className="career-img">
          <img src={cv} alt="" />
        </div>
        <div className="career-fom">
          <form onSubmit={handleSubmit}>
            <div>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Mobile Number:</label>
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Qualification:</label>
              <select
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                required
              >
                <option value="" className='text-gray-400 '>Select Qualification</option>
                <option value="B.A">B.A</option>
                <option value="B.Arch">B.Arch</option>
                <option value="B.B.A/B.M.S">B.B.A/B.M.S</option>
                <option value="B.Ed">B.Ed</option>
                <option value="B.Com">B.Com</option>
                <option value="B.Farma">B.Farma</option>
                <option value="B.sc">B.sc</option>
                <option value="Bachelor's Degree">Bachelor's Degree/BE</option>
                <option value="BCA">BCA</option>
                <option value="CA">CA</option>
                <option value="CS">CS</option>
                <option value="ITI">ITI</option>
                <option value="LLB">LLB</option>
                <option value="LLM">LLM</option>
                <option value="ICAM">ICAM</option>
                <option value="M.A">M.A</option>
                <option value="M.Arch">M.Arch</option>
                <option value="M.B.A/B.M.S">M.B.A/M.M.S</option>
                <option value="M.Ed">M.Ed</option>
                <option value="M.Com">M.Com</option>
                <option value="M.Farma">M.Farma</option>
                <option value="M.sc">M.sc</option>
                <option value="Master's Degree">Master's Degree</option>
                <option value="MCA">MCA</option>
                <option value="MCA">MCA</option>
                <option value="MCS">MCS</option>

                <option value="PhD">PhD</option>
                <option value="Diploma">Diploma</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div>
              <label>Designation:</label>
              <input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Experience:</label>
              <input
                type="number"
                name="experienceYears"
                value={formData.experienceYears}
                onChange={handleChange}
                placeholder="Years"
                required
              />
              <input
                type="number"
                name="experienceMonths"
                value={formData.experienceMonths}
                onChange={handleChange}
                placeholder="Months"
                required
              />
            </div>

            <div>
              <label>Expected Salary:</label>
              <input
                className={`!w-full`}
                type="number"
                name="expectedSalary"
                value={formData.expectedSalary}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Address:</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>

            {/* -----------add-captcha--------------- */}
            {/* <div> */}
            <ReCAPTCHA
              sitekey='6Ley3HcqAAAAABaY3AItcEDCV7emM-X-sXp9Wa40'
              type=''
              onChange={hanclecaptchachange}
            />
            {/* </div> */}

            <div>
              <label style={{ marginBottom: '0px' }}>Please send your resume to our email address:</label>
              {/* <input
                type="file"
                name="resume"
                accept=".pdf, .doc, .docx"  // Restrict file type to PDF and Word documents
                onChange={handleChange}
                required
              /> */}
              {/* {fileError && <p style={{ color: 'red' }}>{fileError}</p>} */}
              {/* <p style={{ fontSize: '15px' }}> </p> */}
              <p>shreejobconsultant@gmail.com</p>

            </div>


            <button className='career-btn' type="submit">Submit</button>
          </form>
        </div>
      </div>
      <Footer1 />
      <Footerlast />
    </>
  );
};

export default JobApplicationForm;


